import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { SSRProvider } from 'react-bootstrap'

import GlobalEventsContext from '@shared/components/global-events/GlobalEventsContext'
import ToastrContext from '@shared/components/toastr/ToastrContext'
import useApp from '@shared/hooks/useApp'
import styles from './style.module.scss'
import ChessPopAdMweb from '../chess/chessPopAd/chessPopAdMweb'
// import { useState } from 'react'
// import { firebaseRemoteConfig } from '@shared/firebase/remoteConfig'
// import ATSExecution from '../atsExecution'

const MyApolloProvider = dynamic(() => import('@graphql/index'))
const ErrorBoundary = dynamic(() => import('@shared/components/errorBoundary'))
// const LazyLoad = dynamic(() => import('@shared/components/lazyLoad'))
// const LazyLoadScript = dynamic(() => import('@shared/components/lazyLoadScript'))
const MainHeader = dynamic(() => import('@shared/components/header'))
const Footer = dynamic(() => import('@shared/components/footer'))
// const GoogleOneTapLogin = dynamic(() => import('@shared/components/googleOneTapLogin'))
const StickyAds = dynamic(() => import('@shared/components/ads/sticky'))
// const GoogleAnalytics = dynamic(() => import('@shared/components/scripts/googleAnalytics'))
// const PopupAds = dynamic(() => import('@shared/components/ads/popup'))
// const HVR = dynamic(() => import('@shared/components/ads/hvr'))
// const Glance = dynamic(() => import('@shared/components/ads/glance'))
// const GlanceSticky = dynamic(() => import('@shared/components/ads/glanceSticky'))
const Toastr = dynamic(() => import('@shared/components/toastr/Toastr'))
const AdScript = dynamic(() => import('@shared/components/adScript'), { ssr: false })
// const ATSExecution = dynamic(() => import('@shared/components/atsExecution'), { ssr: false })
// const GoogleTagManager = dynamic(() => import('@shared-submodule/sm-components/googleTagManager'))

function AppLayout({ children, pageProps }) {
  const { isAmp, isPreview, stateGlobalEvents, dispatchGlobalEvents, state, dispatch, isGlanceView, router } = useApp()
  typeof window !== 'undefined' && document.body.setAttribute('data-light-mode', window.localStorage.getItem('ot-theme') === 'dark' ? 'false' : 'true')
  // const [atsEnable, setAtsEnable] = useState(false)

  // if (typeof window !== 'undefined') {
  //   firebaseRemoteConfig.init().then((data) => {
  //     setAtsEnable(data)
  //   })
  // }

  // const AddAdsScript = () => {
  //   if (!isMobileWebView) {
  //     if (!isAmp) {
  //       return (
  //         <>
  //           <LazyLoad>
  //             <LazyLoadScript />
  //             <HVR />
  //             {!isStaticPage && (
  //               <>
  //                 <StickyAds />
  //                 <PopupAds />
  //               </>
  //             )}
  //           </LazyLoad>
  //         </>
  //       )
  //     } else return null
  //   } else return null
  // }

  return (
    <SSRProvider>
      {/* <AddAdsScript /> */}
      {/* <LazyLoad> */}
        {/* <GoogleTagManager /> */}
        {/* <PopupAds /> */}
        <StickyAds />
        <AdScript />
        {/* <ATSExecution /> */}
      {/* </LazyLoad> */}
      {/* {
        atsEnable && (
          <>
            <AdScript />
            <ATSExecution />
          </>
        )
      } */}
      {/* <GoogleAnalytics /> */}
      <ChessPopAdMweb/>
      <ErrorBoundary>
        <GlobalEventsContext.Provider value={{ stateGlobalEvents, dispatchGlobalEvents }} >
          <ToastrContext.Provider value={{ state, dispatch }} >
            <MyApolloProvider dispatch={dispatch} router={router}>
              {!isAmp && !isPreview && <MainHeader />}
              {/* <GoogleOneTapLogin/> */}
              <main className={`${styles.mainContent}`}>{children}</main>
              {(!isAmp && !isPreview && !isGlanceView) && <Footer />}
            </MyApolloProvider>
            {state?.message && <Toastr type={state?.type} msg={state?.message} btnTxt={state?.btnTxt} />}
          </ToastrContext.Provider>
        </GlobalEventsContext.Provider>
      </ErrorBoundary>
    </SSRProvider>
  )
}
AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageProps: PropTypes.object
}
export default AppLayout
