import React from 'react'
import CustomLink from '../customLink'
import styles from './style.module.scss'

function WordleWidget() {
  return (
    <CustomLink href={'/puzzle'}>
      <div className={styles.container}>
      </div>
    </CustomLink>
  )
}

export default WordleWidget
