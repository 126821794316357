import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { CrossMweb } from '../../../ctIcons'
import { useRouter } from 'next/router'
import CustomLink from '@shared/components/customLink'
import useWindowSize from '@shared/hooks/windowSize'

function ChessPopAdMweb() {
  const [isShow, setIsShow] = useState(true)
  const router = useRouter()
  const [width] = useWindowSize()
  useEffect(() => {
    const handleRouteChange = () => {
      setIsShow(true)
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  if (width > 767 || router.pathname !== '/') return null

  if (isShow) {
    return (
      <div className={styles.popupAddMweb}>
        <button onClick={() => setIsShow(false)} className={styles.closeButton}>
          <CrossMweb />
        </button>
        <CustomLink href={'/hockey-india-league'}>
          <div style={{ position: 'absolute', bottom: 0, height: '150px', width: '100%' }}></div>
        </CustomLink>
      </div>
    )
  } else return null
}

export default ChessPopAdMweb
